/* eslint-disable react/no-multi-comp */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AlertIcon from '@material-ui/icons/ReportProblemRounded';
import NewsIcon from '@material-ui/icons/EmailRounded';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import { HeadsetMicRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    bottomNavBarContainer: {
        width: '100%',
        display: 'inline-block'
    }
}));

const NewsIconWithBadge = () => {
    const { unreadNews, pageSize } = useSelector((state) => state.newsAndAlerts);

    if (unreadNews?.length > 0) {
        return (
            <Badge
                badgeContent={unreadNews.length === pageSize ? `${pageSize}+` : unreadNews.length}
                color="secondary"
            >
                <NewsIcon />
            </Badge>
        );
    }
    return <NewsIcon />;
};

const AlertsIconWithBadge = () => {
    const { unreadAlerts, pageSize } = useSelector((state) => state.newsAndAlerts);

    if (unreadAlerts?.length > 0) {
        return (
            <Badge
                badgeContent={unreadAlerts.length === pageSize ? `${pageSize}+` : unreadAlerts.length}
                color="secondary"
            >
                <AlertIcon />
            </Badge>
        );
    }
    return <AlertIcon />;
};

function BottomNavBar({
    className,
    ...rest
}) {
    const { customerId } = useParams();
    const classes = useStyles();
    const location = useLocation();

    const onNavigationActionClick = (e) => {
        e.currentTarget.blur();
    };

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <BottomNavigation
                value={location.pathname}
                showLabels
            >
                <BottomNavigationAction onClick={onNavigationActionClick} label="Alerts" key="bnbAlerts" icon={<AlertsIconWithBadge />} component={Link} to={`/${customerId}/alerts`} value={`/${customerId}/alerts`} />
                <BottomNavigationAction onClick={onNavigationActionClick} label="News" key="bnbNews" icon={<NewsIconWithBadge />} component={Link} to={`/${customerId}/news`} value={`/${customerId}/news`} />
                <BottomNavigationAction onClick={onNavigationActionClick} label="Support" key="bnbContact" icon={<HeadsetMicRounded />} component={Link} to={`/${customerId}/support`} value={`/${customerId}/contact`} />
            </BottomNavigation>
        </div>
    );
}

BottomNavBar.propTypes = {
    className: PropTypes.string
};

export default BottomNavBar;