import React from 'react';
import PropTypes from 'prop-types';
import OneWayPopupDialog from 'src/components/MessageEvents/OneWayPopupDialog';
import TwoWayPopupDialog from 'src/components/TwoWayMessaging/TwoWayPopupDialog';

const getServiceTypeComponent = (serviceType) => {
    switch (serviceType) {
        case 'OneWay':
        case 'Group':
        case 'Survey':
            return OneWayPopupDialog;
        case 'TwoWay':
        case 'Discussion':
            return TwoWayPopupDialog;
        default:
            return null;
    }
};

export const getServiceTypeIsSupportedInPopup = (serviceType) => {
    return Boolean(getServiceTypeComponent(serviceType));
};

const PopupDialogFactory = ({ open, onClose, selectedEvent, inputSourceMappings, navigateToNextEvent, navigateToPreviousEvent }) => {
    const serviceType = selectedEvent?.serviceType ?? null;
    const Component = getServiceTypeComponent(serviceType);

    if (Component) {
        return (
            <Component
                open={open}
                onClose={onClose}
                selectedEvent={selectedEvent}
                inputSourceMappings={inputSourceMappings}
                recipients={selectedEvent?.recipients}
                content={selectedEvent?.content}
                createdDate={selectedEvent?.created}
                navigateToNextEvent={navigateToNextEvent}
                navigateToPreviousEvent={navigateToPreviousEvent}
            />
        );
    }

    return null;
};

PopupDialogFactory.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object,
    inputSourceMappings: PropTypes.array,
    navigateToNextEvent: PropTypes.func,
    navigateToPreviousEvent: PropTypes.func
};

export default PopupDialogFactory;