import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    AppBar,
    IconButton,
    Toolbar,
    Hidden,
    Drawer,
    Chip
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ProfileDrawer from 'src/components/Profile/ProfileDrawer';
import ProfileButton from 'src/components/Profile/ProfileButton';
import TopBarIcon from 'src/components/Notifications/TopBarIcon';
import NotificationsDrawer from 'src/components/Notifications/NotificationsDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { SET_NOTIFICATIONSDRAWER_OPEN } from 'src/actions/notificationActions';
import { HeadsetMicRounded, UpdateRounded } from '@material-ui/icons';
import { OpenProfileDrawer, ServiceWorkerUpdated } from 'src/actions';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        zIndex: '1200'
    },
    toolbar: {
        backgroundColor: theme.palette.background.blue,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.blue
        },
        [theme.breakpoints.up('lg')]: {
            paddingRight: theme.spacing(3)
        }
    },
    flexGrow: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center'
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    logo: {
        width: 256,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.blue,
        [theme.breakpoints.up('sm')]: {
            maxHeight: 64
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 56
        },
        ...theme.mixins.toolbar
    },
    logoImgWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logoImg: {
        maxWidth: '75%'
    },
    toolbarButtons: {
        display: 'flex'
    },
    supportLink: {
        color: 'inherit'
    },
    modalBackdrop: {
        backgroundColor: 'transparent'
    },
    buttonDrawerPaper: {
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(8),
            maxHeight: 64
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
            maxHeight: 56
        }
    },
    buttonDrawerContent: {
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.white,
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        overflow: 'hidden'
    },
    updateAvailableMobile: {
        flex: 0
    }
}));

function TopBar({
    onOpenNavBarMobile,
    className,
    ...rest
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
    const [buttonsDrawerOpen, setButtonsDrawerOpen] = useState(false);
    const { customerId } = useParams();

    const { notificationsDrawerOpen } = useSelector((state) => state.notifications);
    const setNotificationsDrawerOpen = (value) => dispatch({ type: SET_NOTIFICATIONSDRAWER_OPEN, data: value });
    const { serviceWorkerUpdated, openProfileDrawer } = useSelector((state) => state.app);

    React.useEffect(() => {
        if (openProfileDrawer) {
            setProfileDrawerOpen(true);
            dispatch(OpenProfileDrawer(false));
        }
    }, [dispatch, openProfileDrawer]);

    const profileButtonClick = () => {
        setProfileDrawerOpen(true);
    };

    const profileDrawerClose = () => {
        setProfileDrawerOpen(false);
        setButtonsDrawerOpen(false);
    };

    const notificationsButtonClick = () => {
        setNotificationsDrawerOpen(true);
    };

    const notificationsDrawerClose = () => {
        setButtonsDrawerOpen(false);
        setNotificationsDrawerOpen(false);
    };

    const toolbarButtonsClick = () => {
        setButtonsDrawerOpen(!buttonsDrawerOpen);
    };

    const updateServiceWorker = async (e) => {
        e.preventDefault();

        await dispatch(ServiceWorkerUpdated());

        if ('serviceWorker' in navigator) {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            if (serviceWorkerRegistration.waiting) {
                serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });

                serviceWorkerRegistration.waiting.addEventListener('statechange', (e) => {
                    if (e.target.state === 'activated') {
                        window.location.reload();
                    }
                });
            }
        }
    };

    return (
        <>
            <AppBar
                {...rest}
                className={classes.root}
                color="primary"
            >
                <Toolbar disableGutters className={classes.toolbar}>
                    <Hidden lgUp>
                        <IconButton
                            color="inherit"
                            onClick={onOpenNavBarMobile}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <div className={classes.logo}>
                        <div className={classes.logoImgWrapper}>
                            <img
                                alt="Logo"
                                src="/images/logos/smartdialog_logo.png"
                                className={classes.logoImg}
                            />
                        </div>
                        <Hidden lgUp>
                            {serviceWorkerUpdated && <Chip size="small" className={classes.updateAvailableMobile} color="primary" icon={<UpdateRounded />} label="Update available, click to update" onClick={updateServiceWorker} />}
                        </Hidden>
                    </div>
                    <div className={classes.flexGrow}>
                        {serviceWorkerUpdated && <Chip color="primary" icon={<UpdateRounded />} label="Update available, click to update" onClick={updateServiceWorker} />}
                    </div>
                    <Hidden mdDown className={classes.toolbarButtons}>
                        <Link to={`/${customerId}/support`} className={classes.supportLink}><HeadsetMicRounded /></Link>
                        <TopBarIcon onClick={notificationsButtonClick} />
                        <ProfileButton onClick={profileButtonClick} />
                    </Hidden>
                    <Hidden lgUp>
                        <IconButton
                            color="inherit"
                            onClick={toolbarButtonsClick}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <Hidden lgUp>
                <Drawer
                    ModalProps={{
                        BackdropProps: {
                            className: classes.modalBackdrop
                        }
                    }}
                    classes={{
                        paper: classes.buttonDrawerPaper
                    }}
                    anchor="right"
                    open={buttonsDrawerOpen}
                    onClose={() => setButtonsDrawerOpen(false)}
                >
                    <div className={classes.buttonDrawerContent}>
                        <div className={classes.flexGrow} />
                        <Link to={`/${customerId}/support`} className={classes.supportLink}><HeadsetMicRounded /></Link>
                        <TopBarIcon onClick={notificationsButtonClick} />
                        <ProfileButton onClick={profileButtonClick} />
                    </div>
                </Drawer>
            </Hidden>
            <ProfileDrawer open={profileDrawerOpen} onClose={profileDrawerClose} />
            <NotificationsDrawer open={notificationsDrawerOpen} onClose={notificationsDrawerClose} />
        </>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
